<template>
	<v-col class="py-0">
		<v-row align="center">
			
			<v-col cols="auto">
				<div class="sub-title-2 font-weight-bold transition-cubic" :class="drawer_left ? '':'pl-10'" style="min-height: 32px; line-height: 32px">{{ products.length + ' ' + $tc('global.catalog.results', products.length) }}</div>
			</v-col>

			<v-col cols="" class="px-8">
				<TextField
						:iconClick="(e) => {getSearch(search)}"
						:placeholder="$t('global.action.search')"
						clearable
						@clear="(e) => {getSearch(search)}"
						@enter="(e) =>  {getSearch(search)}"
						appendIcon="mdi-magnify"
						v-model="search"
				/>
			</v-col>

			<v-col cols="auto" v-if="$vuetify.breakpoint.width >= 650 + (this.drawer_left || this.drawer_right ? 240 : 0)">
				<Button iconVal="mdi-grid-large" :carre="true" :click="() => display = 'grid'" :iconColor="display === 'grid' ? 'primary':''" />
			</v-col>

			<v-col cols="auto" v-if="$vuetify.breakpoint.width >= 650 + (this.drawer_left || this.drawer_right ? 240 : 0)" class="pl-0">
				<Button iconVal="mdi-format-list-bulleted" :carre="true" :click="() => display = 'list'" :iconColor="display === 'list' ? 'primary':''" />
			</v-col>
		</v-row>

		<v-row justify="center" align="center" class="mx-0" v-if="ready && totalProducts !== 0 && products.length > itemsPerPage / 2">
			<v-col align="center" class="pa-0" style="max-width: 120px" v-if="totalProducts > itemsPerPage">
				<Select
						v-model="itemsPerPage"
						:items="itemsPerPageArray"
						:label="$t('single_trad.Table.items_p_p')"
						itemText=""
				/>
			</v-col>

			<v-col class="pa-0" v-if="totalProducts > itemsPerPage">
					<v-pagination
							v-model="page"
							style="margin: 6px 0"
							:length="totalPages"
					/>
			</v-col>

			<v-col v-if="totalProducts > itemsPerPage" style="max-width: 120px"></v-col>
		</v-row>

		<v-row v-if="ready">
			<v-col class="pt-0 overflow-hidden">
				<v-row>
					<v-expand-transition>
						<v-col cols="12" class="pt-1 pb-4" v-if="drawer_right || catalog_ads[0].length > 0">
							<draggable
									v-model="catalog_ads[0]"
									:group="{ name: 'catalog', pull: true }"
									:style="drawer_right ? {minHeight: '200px', border: '4px dashed var(--v-secondary-base)'}:{}"
									:disabled="!drawer_right"
									handle=".handle"
							>
								<Ad
										v-for="(ad, i) in catalog_ads[0]"
										:key="i"
										:edit="drawer_right"
										:item="ad"
										:class="i !== 0 ? 'mt-2':''"
										@delete="catalog_ads[0].splice(i, 1)"
								/>
							</draggable>
						</v-col>
					</v-expand-transition>

					<template v-for="(product, i) in products" v-if="products.length > 0 && products[0].name">
						<v-col :key="i" cols="12" :sm="display === 'grid' ? 6:12" :md="display === 'grid' ? 3:12" :lg="display === 'grid' ? 2:12" :xl="display === 'grid' ? 2:12" class="pt-1 pb-4">
							<Product :product="product" :layout="display" />
						</v-col>

						<v-expand-transition :key="i">
							<v-col cols="12" v-if="(drawer_right || catalog_ads[indexes[i] + 1].length > 0) && i % nbCols === nbCols - 1 ">
								<draggable
										v-model="catalog_ads[indexes[i] + 1]"
										:group="{ name: 'catalog', pull: true }"
										:style="drawer_right ? {minHeight: '200px', border: '4px dashed var(--v-secondary-base)'}:{}"
										:disabled="!drawer_right"
										handle=".handle"
								>
									<Ad
											v-for="(ad, j) in catalog_ads[indexes[i] + 1]"
											v-if="catalog_ads[indexes[i] + 1][j]"
											:key="j"
											:edit="drawer_right"
											:item="ad"
											:class="j !== 0 ? 'mt-2':''"
											@delete="catalog_ads[indexes[i] + 1].splice(j, 1)"
									/>
								</draggable>
							</v-col>
						</v-expand-transition>
					</template>
					
					<v-expand-transition>
						<v-col cols="12" class="pt-0" v-show="(drawer_right || catalog_ads[indexes[products.length - 1] + 1].length > 0) && products.length % nbCols !== 0 " v-if="products.length > 0">
							<draggable
									v-model="catalog_ads[indexes[products.length - 1] + 1]"
									:group="{ name: 'catalog', pull: true }"
									:style="drawer_right ? {minHeight: '200px', border: '4px dashed var(--v-secondary-base)'}:{}"
									:disabled="!drawer_right"
									handle=".handle"
							>
								<Ad
										v-for="(ad, i) in catalog_ads[indexes[products.length - 1] + 1]"
										:key="i"
										:edit="drawer_right"
										:item="ad"
										:class="i !== 0 ? 'mt-2':''"
										@delete="catalog_ads[indexes[products.length - 1] + 1].splice(i, 1)"
								/>
							</draggable>
						</v-col>
					</v-expand-transition>
				</v-row>
			</v-col>
			<v-col style="max-width: 200px;" class="pl-1 pb-6 pt-1" v-show="drawer_right || side_ads.length > 0">
				<draggable
						v-model="side_ads"
						:group="{ name: 'catalog', pull: true }"
						:style="drawer_right ? {minHeight: '200px', border: '4px dashed var(--v-secondary-base)'}:{}"
						class="fill-height"
						:disabled="!drawer_right"
						handle=".handle"
				>
					<Ad
							v-for="(ad, i) in side_ads"
							:key="i"
							:edit="drawer_right"
							:item="ad"
							:class="i !== 0 ? 'mt-2':''"
							:style="drawer_right ? {cursor: 'grab'}:{}"
							@delete="side_ads.splice(i, 1)"
					/>
				</draggable>
			</v-col>
		</v-row>

		<v-row justify="center" align="center" class="mx-0" v-if="ready && totalProducts !== 0">
			<v-col align="center" class="pa-0" style="max-width: 120px" v-if="totalProducts > itemsPerPage">
				<Select
						v-model="itemsPerPage"
						:items="itemsPerPageArray"
						:label="$t('single_trad.Table.items_p_p')"
						itemText=""
				/>
			</v-col>
			<v-col class="pa-0" v-if="totalProducts > itemsPerPage">
					<v-pagination
							v-model="page"
							style="margin: 6px 0"
							:length="totalPages"
					/>
			</v-col>
			<v-col v-if="totalProducts > itemsPerPage" style="max-width: 120px"></v-col>
		</v-row>

		<v-row justify="center" class="pa-4" v-if="!ready">
			<v-progress-circular indeterminate color="primary" size="40" />
		</v-row>
	</v-col>
</template>

<script>
	export default {
		name: "Catalog",
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			Select: () => import('@/components/fields/Select.vue'),
			Product: () => import('@/components/Product.vue'),
			Ad: () => import('@/components/widgets/Announcement.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			draggable: () => import('vuedraggable')
		},
		props: ['pevents'],
		data(){
			return {
				display: 'grid',
				products: [],
				filters: {... this.$route.query},
				totalProducts: 0,
				itemsPerPageArray: [12, 18, 32, 64],
				itemsPerPage: 16,
				page: 1,
				ready: false,
				catalog_ads: [],
				side_ads: [],
				indexes: [],
				providers:[],
				categories:[],
				base_ads: [],
				search: ''
			}
		},
		computed: {
			totalPages() {
				return Math.ceil(this.totalProducts / this.itemsPerPage)
			},
			drawer_left(){
				return this.$store.getters['marketplace/drawer']
			},
			drawer_right(){
				return this.$store.getters['marketplace/drawer_ads']
			},
			nbCols(){
				if(this.$vuetify.breakpoint.width < 600){
					return 1
				}
				else if(this.$vuetify.breakpoint.width >= 600 && this.$vuetify.breakpoint.width < 960){
					return 2
				}
				else if(this.$vuetify.breakpoint.width >= 960 && this.$vuetify.breakpoint.width < 1264){
					return 4
				}
				else if(this.$vuetify.breakpoint.width >= 1264 && this.$vuetify.breakpoint.width < 1904){
					return 6
				}
				else if(this.$vuetify.breakpoint.width >= 1904){
					return 6
				}
			}
		},
		watch: {
			drawer_right(val){
				if(!val){
					this.$wsc.createItem('resource/announcement', {side_ads: this.side_ads, catalog_ads: this.catalog_ads}, success => {
						this.$store.dispatch('snackbar/success', this.$t('snackbar.EntityField.saveChanges.success'))
					}, fail => {
					})
				}
			},
			'$vuetify.breakpoint.width'(val){
				if(val < 650 + (this.drawer_left || this.drawer_right ? 240 : 0)){
					this.display = 'grid'
				}

				this.initCatalogAds()
			},
			'$route'(to, from) {
				if(to.fullPath !== from.fullPath){
					this.setFilters()
					this.getProducts()
				}
			},
			page(val){
				let query = {...this.$route.query}

				query['page'] = val
				query['page_size'] = this.itemsPerPage

				this.$router.push({query})
			},
			itemsPerPage(val){
				let query = {...this.$route.query}

				query['page_size'] = val
				query['page'] = 1

				this.$router.push({query})
			}
		},
		methods: {
			initCatalogAds(){
				let index = 0

				if(!this.catalog_ads[0])
					this.catalog_ads.push([])

				this.products.forEach((e, i) => {
					this.indexes.splice(i, 1, index)

					if(i % this.nbCols === this.nbCols - 1){
						if(!this.catalog_ads[i])
							this.catalog_ads.push([])
						index++
					}
				})

				if(!this.catalog_ads[this.products.length])
					this.catalog_ads.push([])
			},
			setFilters(){
				this.filters = {... this.$route.query}

				this.itemsPerPage = this.$route.query['page_size'] ? parseInt(this.$route.query['page_size']) : 16,
				this.page = this.$route.query['page'] ? parseInt(this.$route.query['page']) : 1

				this.filters['page_size'] = this.itemsPerPage
				this.filters['page'] = this.page
				this.search = this.filters['query_filter']
			},
			getProducts(){
				this.ready = false
				this.$set(this.filters, 'marketplace', true)

				this.$wsc.getList('product', this.filters, success => {
					if(this.filters['page_size'] || this.filters['page']){
						this.products = success.results
						this.totalProducts = success.count
					}
					else {
						this.products = success
						this.totalProducts = success
					}

					this.initCatalogAds()

					if (this.products.length > 0){
						this.fetchCatalogAds()
					}
					else {
						this.catalog_ads = [[]]
						this.side_ads = []
					}

					this.ready = true
				}, fail => {
					this.ready = true
				})
			},
			fetchCatalogAds(){
				this.$wsc.getList('resource/announcement', {}, success => {
					let res1 = []

					success.catalog_ads.forEach((tab, i) => {
						res1.push([])

						tab.forEach(a => {
							let ad = this.base_ads.find(e => e.id === a.id)
							ad['__position'] = a['__position']

							if(ad){
								res1[i].push(ad)
							}
						})
					})

					this.catalog_ads = res1

					this.side_ads = []

					success.side_ads.forEach(a => {
						let ad = this.base_ads.find(e => e.id === a.id)

						if(ad){
							this.side_ads.push(ad)
						}
					})

					this.initCatalogAds()
				}, fail => {

				})
			},
			getSearch(val){
				if(val === null || val === '')
					delete this.filters['query_filter']
				else
					this.filters['query_filter'] = val

				this.$router.push({query: this.filters})
			},
			setUpHooks(){
				this.pevents.wait('give-filters', (categories, providers) => {
					this.categories = categories
					this.providers = providers
                })

				this.pevents.wait('update-app', () => {
					this.setFilters()
					this.getProducts()
				})
			},
			fetchAds(){
				this.$wsc.getList('announcement', {}, success => {
					this.pevents.notify('ads', success)
					this.base_ads = success

					let res = []

					this.catalog_ads.forEach(e => {
						let ress = []

						e.forEach(r => {
							ress.push(val.find(f => f.id === r.id))
						})

						res.push(ress)
					})

					this.catalog_ads = res

					let res2 = []

					this.side_ads.forEach(e => {
						res2.push(val.find(f => f.id === e.id))
					})

					this.side_ads = res2

					this.getProducts()
				}, fail => {

				})
			}
		},
		created() {
			this.setUpHooks()
			this.setFilters()
			this.fetchAds()
		},
		beforeRouteLeave(to, from, next){
			this.$store.commit('marketplace/CHANGE_DRAWER_ADS_VAL', false)
			this.$store.commit('marketplace/CHANGE_DRAWER_VAL', true)
			next()
		}
	}
</script>

<style scoped>

</style>
